import {SearchIcon} from '@primer/octicons-react'

import styles from './FilterInputIcon.module.css'

export const FilterInputIcon = () => {
  return (
    <div className={styles.Box_0}>
      <SearchIcon aria-label="Search" className={styles.Octicon_0} />
    </div>
  )
}

try{ FilterInputIcon.displayName ||= 'FilterInputIcon' } catch {}